"use client";
import React, { useState, useEffect, useCallback } from "react";
import { Plus, Minus } from "lucide-react";
import Button from "@/app/components/button";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/app/components/card";
import { Label } from "@/app/components/label";
import { Input } from "@/app/components/input";

const calculateResult = (
  commercialRate: number,
  propertyGrowth: number,
  debtRatio: number,
  rentalYield: number,
  setResult: React.Dispatch<React.SetStateAction<number>>,
) => {
  const debtRatioDecimal = debtRatio / 100;
  const calculation =
    (1 -
      debtRatioDecimal -
      (commercialRate / 100) * debtRatioDecimal +
      rentalYield / 100 +
      propertyGrowth / 100) /
      (1 - debtRatioDecimal) -
    1;
  setResult(calculation * 100);
};

export default function InvestmentStrategyCalculator() {
  const [commercialRate, setCommercialRate] = useState(4);
  const [propertyGrowth, setPropertyGrowth] = useState(6);
  const [debtRatio, setDebtRatio] = useState(50);
  const [rentalYield, setRentalYield] = useState(4);
  const [result, setResult] = useState(0);

  useEffect(() => {
    calculateResult(
      commercialRate,
      propertyGrowth,
      debtRatio,
      rentalYield,
      setResult,
    );
  }, [commercialRate, propertyGrowth, debtRatio, rentalYield]);

  const adjustValue = useCallback(
    (
      setter: React.Dispatch<React.SetStateAction<number>>,
      value: number,
      adjustment: number,
    ) => {
      setter(Math.max(0, value + adjustment));
    },
    [],
  );

  return (
    <Card className="w-full max-w-3xl mx-auto">
      <CardHeader>
        <CardTitle className="text-2xl font-bold">
          Investiční strategie v číslech
        </CardTitle>
      </CardHeader>
      <CardContent className="grid gap-6">
        <div className="grid gap-2">
          <Label htmlFor="commercial-rate">Očekávané komerční sazby (%)</Label>
          <div className="flex items-center gap-2">
            <Button
              className={"!p-3"}
              onClick={() =>
                adjustValue(setCommercialRate, commercialRate, -0.1)
              }
              aria-label="Snížit komerční sazbu"
            >
              <Minus className="!h-4 !w-4 stroke-white" />
            </Button>
            <Input
              id="commercial-rate"
              type="number"
              value={commercialRate.toFixed(1)}
              onChange={(e) => setCommercialRate(parseFloat(e.target.value))}
              className="text-center"
            />
            <Button
              className={"!p-3"}
              onClick={() =>
                adjustValue(setCommercialRate, commercialRate, 0.1)
              }
              aria-label="Zvýšit komerční sazbu"
            >
              <Plus className="!h-4 !w-4 stroke-white" />
            </Button>
          </div>
        </div>
        <div className="grid gap-2">
          <Label htmlFor="property-growth">
            Očekávaný růst cen nemovitostí (%)
          </Label>
          <div className="flex items-center gap-2">
            <Button
              className={"!p-3"}
              onClick={() =>
                adjustValue(setPropertyGrowth, propertyGrowth, -0.1)
              }
              aria-label="Snížit růst cen nemovitostí"
            >
              <Minus className="!h-4 !w-4 stroke-white" />
            </Button>
            <Input
              id="property-growth"
              type="number"
              value={propertyGrowth.toFixed(1)}
              onChange={(e) => setPropertyGrowth(parseFloat(e.target.value))}
              className="text-center"
            />
            <Button
              className={"!p-3"}
              onClick={() =>
                adjustValue(setPropertyGrowth, propertyGrowth, 0.1)
              }
              aria-label="Zvýšit růst cen nemovitostí"
            >
              <Plus className="!h-4 !w-4 stroke-white" />
            </Button>
          </div>
        </div>
        <div className="grid gap-2">
          <Label htmlFor="debt-ratio">Míra zadlužení (%)</Label>
          <div className="flex items-center gap-2">
            <Button
              className={"!p-3"}
              onClick={() => adjustValue(setDebtRatio, debtRatio, -1)}
              aria-label="Snížit míru zadlužení"
            >
              <Minus className="!h-4 !w-4 stroke-white" />
            </Button>
            <Input
              id="debt-ratio"
              type="number"
              value={debtRatio.toFixed(0)}
              onChange={(e) => setDebtRatio(parseFloat(e.target.value))}
              className="text-center"
            />
            <Button
              className={"!p-3"}
              onClick={() => adjustValue(setDebtRatio, debtRatio, 1)}
              aria-label="Zvýšit míru zadlužení"
            >
              <Plus className="!h-4 !w-4 stroke-white" />
            </Button>
          </div>
        </div>
        <div className="grid gap-2">
          <Label htmlFor="rental-yield">Nájemní výnos (%)</Label>
          <div className="flex items-center gap-2">
            <Button
              className={"!p-3"}
              onClick={() => adjustValue(setRentalYield, rentalYield, -0.1)}
              aria-label="Snížit nájemní výnos"
            >
              <Minus className="!h-4 !w-4 stroke-white" />
            </Button>
            <Input
              id="rental-yield"
              type="number"
              value={rentalYield.toFixed(1)}
              onChange={(e) => setRentalYield(parseFloat(e.target.value))}
              className="text-center"
            />
            <Button
              className={"!p-3"}
              onClick={() => adjustValue(setRentalYield, rentalYield, 0.1)}
              aria-label="Zvýšit nájemní výnos"
            >
              <Plus className="!h-4 !w-4 stroke-white" />
            </Button>
          </div>
        </div>
        <div className="mt-6">
          <div className="text-xl font-semibold mb-2">Výsledek výpočtu:</div>
          <p className="text-3xl font-bold text-primary">
            {result.toFixed(2)}%
          </p>
        </div>
      </CardContent>
    </Card>
  );
}
