import React, { ButtonHTMLAttributes } from "react";

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}

const Button: React.FC<ButtonProps> = ({ children, className, ...props }) => {
  return (
    <button
      {...props}
      className={`bg-primary-color text-text-white rounded-sm px-4 py-4 transition-colors duration-100 ease-in hover:bg-primary-color-hover ${className}`}
    >
      {children}
    </button>
  );
};

export default Button;
