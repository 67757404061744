"use client";

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/app/components/form";
import { Input } from "@/app/components/input";
import Button from "@/app/components/button";
import { Textarea } from "@/app/components/textarea";
import emailjs from "@emailjs/browser";

const formSchema = z.object({
  firstName: z.string().min(2, { message: "Jméno musí mít alespoň 2 znaky" }),
  lastName: z.string().min(2, { message: "Příjmení musí mít alespoň 2 znaky" }),
  phone: z.string().regex(/^(\+420)?\s?\d{3}\s?\d{3}\s?\d{3}$/, {
    message: "Neplatné telefonní číslo",
  }),
  email: z.string().email({ message: "Neplatná emailová adresa" }),
  postalCode: z.string().regex(/^\d{3}\s?\d{2}$/, { message: "Neplatné PSČ" }),
  investment: z
    .string()
    .min(1, { message: "Prosím vyplňte předpokládanou investici" }),
  notes: z.string().optional(),
});

const ContactForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
      postalCode: "",
      investment: "",
      notes: "",
    },
  });

  function onSubmit(data: z.infer<typeof formSchema>) {
    setIsSubmitting(true);
    emailjs
      .send("service_l20xywm", "template_1ssl716", data, "KafDDJntcKc2HS5aQ")
      .then(
        (result) => {
          console.log(result.text);
          setIsSubmitting(false);
          setSubmitSuccess(true);
        },
        (error) => {
          console.log(error.text);
          setIsSubmitting(false);
        },
      );
  }

  if (submitSuccess) {
    return (
      <div className="text-center p-6 bg-green-100 rounded-lg">
        <h2 className="text-2xl font-bold text-green-800 mb-2">
          Děkujeme za Váš zájem!
        </h2>
        <p className="text-green-700">
          Brzy se Vám ozveme s dalšími informacemi.
        </p>
      </div>
    );
  }

  return (
    <div
      className="w-full max-w-4xl mx-auto p-6 bg-background-primary rounded-sm shadow-md"
      id={"chci-investovat"}
    >
      <div className="text-2xl font-bold text-center mb-2">
        Zaujal Vás fond?
      </div>
      <div className="text-center mb-6 text-gray-600">
        Nechte nám na sebe kontakt
      </div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <FormField
              control={form.control}
              name="firstName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Jméno</FormLabel>
                  <FormControl>
                    <Input placeholder="Jan" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="lastName"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Příjmení</FormLabel>
                  <FormControl>
                    <Input placeholder="Novák" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Telefon</FormLabel>
                  <FormControl>
                    <Input placeholder="+420 123 456 789" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email</FormLabel>
                  <FormControl>
                    <Input placeholder="jan.novak@example.com" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="postalCode"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>PSČ</FormLabel>
                  <FormControl>
                    <Input placeholder="110 00" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="investment"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Předpokládaná investice</FormLabel>
                  <FormControl>
                    <Input placeholder="1 000 000 Kč" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <FormField
            control={form.control}
            name="notes"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Poznámky</FormLabel>
                <FormControl>
                  <Textarea
                    placeholder="Vaše další otázky nebo poznámky..."
                    {...field}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit" className="w-full" disabled={isSubmitting}>
            {isSubmitting ? "Odesílání..." : "Odeslat"}
          </Button>
        </form>
      </Form>
    </div>
  );
};

export default ContactForm;
