"use client";

import Link from "next/link";
import prague_bnb_fund from "@images/PRAGUE BNB FUND.png";
import Image from "next/image";
import Button from "@/app/components/button";
import { MenuIcon } from "lucide-react";
import { Drawer } from "vaul";
import React from "react";

const Header = () => {
  const [open, setOpen] = React.useState(false);

  return (
    <div
      className={
        "absolute z-10 w-full border-b border-text-white bg-background-secondary"
      }
    >
      <div
        className={
          "container mx-auto flex items-center justify-between px-4 py-2"
        }
      >
        <Image src={prague_bnb_fund} alt={"prague bnb fond logo"} height={80} />
        <div className={"hidden items-center gap-8 text-white lg:flex"}>
          <Link
            href="/#investicni-strategie"
            className={"hover:text-secondary-color-hover transition-all "}
          >
            Investiční strategie
          </Link>
          <Link
            href="/#kontakt"
            className={"hover:text-secondary-color-hover transition-all"}
          >
            Kontakt
          </Link>
          <Link href={"#chci-investovat"}>
            <Button>Chci investovat</Button>
          </Link>
        </div>

        <Drawer.Root open={open} onOpenChange={setOpen} direction="right">
          <Drawer.Trigger asChild>
            <Button
              className={"bg-transparent block lg:hidden"}
              aria-label="Open menu"
            >
              <MenuIcon
                style={{
                  width: "36px",
                  height: "36px",
                  stroke: "var(--text-white)",
                }}
                aria-label="Open menu"
              />
            </Button>
          </Drawer.Trigger>
          <Drawer.Portal>
            <Drawer.Overlay className="fixed inset-0 bg-black/40 z-20" />
            <Drawer.Content
              className="right-0 top-0 bottom-0 fixed z-30 outline-none w-[310px] flex"
              style={
                {
                  "--initial-transform": "calc(100% + 8px)",
                } as React.CSSProperties
              }
            >
              <div className="bg-background-secondary h-full w-full grow p-5 flex flex-col">
                <div className="max-w-md">
                  <Drawer.Title className="mb-6">
                    <Image
                      src={prague_bnb_fund}
                      alt={"prague bnb fond logo"}
                      className={"mx-auto"}
                      height={80}
                    />
                  </Drawer.Title>
                  <hr />
                  <Drawer.Description className="my-6">
                    <div
                      className={"items-start gap-8 text-white flex flex-col"}
                    >
                      <Link
                        onClick={() => setOpen(false)}
                        href="/#investicni-strategie"
                        className={
                          "hover:text-secondary-color-hover transition-all "
                        }
                      >
                        Investiční strategie
                      </Link>
                      <Link
                        onClick={() => setOpen(false)}
                        href="/#kontakt"
                        className={
                          "hover:text-secondary-color-hover transition-all"
                        }
                      >
                        Kontakt
                      </Link>
                      <Link
                        href={"#chci-investovat"}
                        onClick={() => setOpen(false)}
                      >
                        <Button>Chci investovat</Button>
                      </Link>
                    </div>
                  </Drawer.Description>
                </div>
              </div>
            </Drawer.Content>
          </Drawer.Portal>
        </Drawer.Root>
      </div>
    </div>
  );
};

export default Header;
