import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\phuon\\Projekty\\urban wolf\\prague-bnb-fund\\app\\components\\contact-form.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\phuon\\Projekty\\urban wolf\\prague-bnb-fund\\app\\components\\header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\phuon\\Projekty\\urban wolf\\prague-bnb-fund\\app\\components\\investment-strategy-calculator.tsx");
;
import(/* webpackMode: "eager" */ "C:\\Users\\phuon\\Projekty\\urban wolf\\prague-bnb-fund\\node_modules\\next\\dist\\client\\image-component.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\phuon\\Projekty\\urban wolf\\prague-bnb-fund\\node_modules\\next\\dist\\client\\link.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\phuon\\Projekty\\urban wolf\\prague-bnb-fund\\public\\assets\\images\\national-theatre.webp");
;
import(/* webpackMode: "eager" */ "C:\\Users\\phuon\\Projekty\\urban wolf\\prague-bnb-fund\\public\\assets\\images\\PRAGUE BNB FUND LOGO.png");
;
import(/* webpackMode: "eager" */ "C:\\Users\\phuon\\Projekty\\urban wolf\\prague-bnb-fund\\public\\assets\\images\\prague-castle.webp");
;
import(/* webpackMode: "eager" */ "C:\\Users\\phuon\\Projekty\\urban wolf\\prague-bnb-fund\\public\\assets\\images\\prague-view.webp");
